import React , {Component} from 'react';
import './index.css';
import product1 from '../../assets/img/washing-product1.jpg'
import product2 from '../../assets/img/washing-product2.jpg'
import product3 from '../../assets/img/washing-product3.jpg'
import product4 from '../../assets/img/washing-product4.jpg'

const products = [
    product1,product2,product3,product4
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="wash-agent">
                <p className="wash-mobile-title">产品实拍</p>
                <p className="wash-mobile-desc">PRODUCT PHOTOGRAPH</p>
                <div style={{display: 'flex',justifyContent: "center"}}>
                    {
                        products.map(item=><div style={{flex: 1}}>
                            <img src={item}/>
                        </div>)
                    }
                </div>
            </div>)
    }
}
export default home;
