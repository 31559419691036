import React, {Component} from 'react';
import echarts from 'echarts';
import geoJson from '../../assets/json/china.json';
import options from './mapConfig'
class Map extends Component{
    constructor(props)
    {
        super(props);
    }
    componentDidMount() {
        this.initECharts();
    }

    initECharts = () =>{
        echarts.registerMap('china', geoJson);
        const chart = echarts.init(document.getElementById('map'));
        chart.setOption(options);
    }
    render() {
        return(
            <div style={{backgroundColor:"#F6FAFD",padding: '5em 0',textAlign: 'center'}}>
                <p className="cheyoo-map-title">覆盖全国的服务能力</p>
                <p className="cheyoo-map-desc">2000+ 服务网点线上线下全方位为您服务</p>
                <div id="map" style={{width:'100%',height:'90vh'}} />
            </div>)
    }
}
export default Map;
