import React , {Component} from 'react';
import './index.css';
import safety1 from '../../assets/img/washing-safety1.jpg'
import safety2 from '../../assets/img/washing-safety2.jpg'
import safety3 from '../../assets/img/washing-safety3.jpg'
import safety4 from '../../assets/img/washing-safety4.jpg'

const safety = [
    {
        icon: safety1,
        label: '物理防护',
        desc: '全面的防撞设计，10处防护监测，触压感应，一触即停。'
    },
    {
        icon: safety2,
        label: '识别技术',
        desc: '图像动态检测，停偏提醒，风险车辆提醒，防儿童闯入保护。'
    },
    {
        icon: safety3,
        label: 'APP智能操控',
        desc: '手机远程控制，突发状况主动停止。'
    },
    {
        icon: safety4,
        label: '保险公司承保',
        desc: '与保险公司达成战略合作，为每一单洗车风险承保。'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="wash-mobile">
                <p className="wash-mobile-title">安全保护</p>
                <p className="wash-mobile-desc">SAFETY PROTECTION</p>
                <div style={{display: 'flex',justifyContent: "center"}}>
                    {
                        safety.map(item=><div style={{flex: 1}}>
                            <div style={{width: '285px',boxShadow: "0 0 29px 0 #BFC7CE"}}>
                                <img src={item.icon}/>
                                <p style={{margin: "20px 1em 15px 1em",fontSize: '1.2em',fontWeight: 'bold',color:"#404040"}}>{item.label}</p>
                                <p style={{opacity: "0.6",fontSize: '1em',color:"#000000",margin:'0 1em',height: '5em'}}>{item.desc}</p>
                            </div>
                        </div>)
                    }
                </div>
            </div>)
    }
}
export default home;
