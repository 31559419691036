import React , {Component} from 'react';
import './index.css';
import Header from '../../header'
import Banner from './banner'
import Strength from './strength'
import Footer from '../../footer'
import Agent from './agent'
import Mobile from './mobile'
import Safety from './safety'
import Product from './product'
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div>
                <Header style = "transparent_black" />
                <Banner/>
                <Strength/>
                <Mobile/>
                <Agent/>
                <Safety/>
                <Product/>
                <Footer/>
            </div>)
    }
}
export default home;
