import React , {Component} from 'react';
import './index.css';
import Header from '../../header'
import Footer from '../../footer'
import Banner from './banner'
import Job from './job'
const info = [
    {
        name:"地址",
        desc:"武汉市江汉区世界贸易大厦22层3-5室"
    },
    {
        name:"邮箱",
        desc:"hr@cheyoo.com.cn"
    },
    // {
    //     name:"电话",
    //     desc:"027-58905705"
    // },
    // {
    //     name:"传真",
    //     desc:"027-58905718"
    // }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div style={{background:'#FFF',padding: '5em 0'}}>
                <div className="join-contact-plat">
                    {info.map(item =><div>
                            <span className="join-contact-name">{item.name}</span>
                            <span className="join-contact-info">{item.desc}</span>
                    </div>)}
                </div>
            </div>)
    }
}
export default home;
