import React , {Component} from 'react';
import './index.css';
class Module extends Component{
    constructor(props)
    {
        super(props);
    }
    navigator = (url) =>{
        window.location.href = '/' + url;
    }
    render() {
        return(
            <div className="brand">
                <div style={{padding: 0,margin: 0,height: "252px"}}>
                    <div onClick={this.navigator.bind(null,"cheyoo")} className="brand-plat" style={{backgroundImage: `url(${require('../../assets/img/cheyoo.jpg')})`}}>
                        <p>车友网</p>
                    </div>
                    <div onClick={this.navigator.bind(null,"union")} className="brand-plat" style={{backgroundImage: `url(${require('../../assets/img/transfer.jpg')})`}}>
                        <p>联运中国</p>
                    </div>
                </div>
                <div className="brand-desc" >
                    <p>提供信息化、智能化产品</p>
                    <p>深度优化用户体验、简化管理流程</p>
                    <p>构架全方位一体化的智慧场景解决方案</p>
                </div>
            </div>)
    }
}
export default Module;
