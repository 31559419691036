import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/gas-strength1.png'
import strength2 from '../../assets/img/gas-strength2.png'
import strength3 from '../../assets/img/gas-strength3.png'

const strength = [
    {
        icon: strength1,
        label: '数据信息化',
        desc: '加油全数据自动信息化、全系统联动'
    },
    {
        icon: strength2,
        label: '便捷灵活',
        desc: '兼容市面主流的各类软硬件系统，提供开放平台供系统服务商接入'
    },
    {
        icon: strength3,
        label: '高效稳定',
        desc: '站级服务器与云架构协同配合，灾备、风控'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="gas-strength">
                <div>
                    <img src= {strength[0].icon}/>
                    <div style={{width: "300px"}}>
                        <p className="gas-strength-title">{strength[0].label}</p>
                        <p className="gas-strength-desc">{strength[0].desc}</p>
                    </div>
                </div>
                <div style={{flex: 1}}/>
                <div>
                    <img src= {strength[1].icon}/>
                    <div style={{width: "300px"}}>
                        <p className="gas-strength-title">{strength[1].label}</p>
                        <p className="gas-strength-desc">{strength[1].desc}</p>
                    </div>
                </div>
                <div style={{flex: 1}}/>
                <div>
                    <img src= {strength[2].icon}/>
                    <div style={{width: "300px"}}>
                        <p className="gas-strength-title">{strength[2].label}</p>
                        <p className="gas-strength-desc">{strength[2].desc}</p>
                    </div>
                </div>
            </div>)
    }
}
export default home;
