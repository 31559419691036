import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/cheyoo_strength_1.jpg'
import strength2 from '../../assets/img/cheyoo_strength_2.jpg'
import strength3 from '../../assets/img/cheyoo_strength_3.jpg'
import strength4 from '../../assets/img/cheyoo_strength_4.jpg'
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="union-strength">
                <div className="union-strength-plat">
                    <div className="cheyoo-strength-plat-img-top" style={{flex: 1}}>
                        <img src={strength1}/>
                    </div>
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">Smart</p>
                        <p className="union-strength-plat-title">智慧加油站</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-bg-sub-text">GasStation</p>
                            <p className="union-strength-plat-subtitle">全套管理、营销的软硬件解决方案</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">实现加油站信息化、智能化的改造</p>
                        <p className="union-strength-plat-desc">节约管理成本、提高营销效率、增强用户体验</p>
                    </div>
                </div>

                <div className="union-strength-plat">
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">Senseless</p>
                        <p className="union-strength-plat-title">无感支付</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-bg-sub-text">Payment</p>
                            <p className="union-strength-plat-subtitle">基于车牌、人脸、ETC等的识别技术</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">基于多支付通道，落地无接触、无感知付款的应用场景</p>
                        <p className="union-strength-plat-desc">带来完美的支付体验</p>
                    </div>
                    <div className="cheyoo-strength-plat-img-right" style={{flex: 1}}>
                        <img src={strength2}/>
                    </div>
                </div>

                <div className="union-strength-plat">
                    <div className="cheyoo-strength-plat-img-left" style={{flex: 1}}>
                        <img src={strength3}/>
                    </div>
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">CarWash</p>
                        <p className="union-strength-plat-title">洗车机器人</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-bg-sub-text">Robot</p>
                            <p className="union-strength-plat-subtitle">全产业链自主研发</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">线上监控、管理、营销配套支撑</p>
                        <p className="union-strength-plat-desc">干净快速、节能环保的洗车体验</p>
                    </div>
                </div>

                <div className="union-strength-plat">
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">RidersDriving</p>
                        <p className="union-strength-plat-title">车友自驾</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-subtitle">拼车、领队、达人、路书定制</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">突破传统的自驾游模式</p>
                        <p className="union-strength-plat-desc">全新的游前、游中、游后体验</p>
                    </div>
                    <div className="cheyoo-strength-plat-img-bottom" style={{flex: 1}}>
                        <img src={strength4}/>
                    </div>
                </div>
            </div>)
    }
}
export default home;
