import React , {Component} from 'react';
import './index.css';
import agent1 from '../../assets/img/travelling-service1.png'
import agent2 from '../../assets/img/travelling-service2.png'
import agent3 from '../../assets/img/travelling-service3.png'
import agent4 from '../../assets/img/travelling-service4.png'

const agent = [
    {
        icon: agent1,
        label: '灵活的运输网络服务',
        desc: '支持大规模运输网络计算，提供出行时间设置、里程计算、运输规划等货运运前规划能力。'
    },
    {
        icon: agent2,
        label: '监控及运输管理',
        desc: '鹰眼高性能轨迹服务，集轨迹、储存、查询、纠偏、数据对比、数据分析、地理围栏为一体的运输管理体系。'
    },
    {
        icon: agent3,
        label: '业务定制服务',
        desc: '专业定制化一体化服务，为个别特定业务提供定制线路，规划指定道理避让、网点运营的手动绘制。'
    },
    {
        icon: agent4,
        label: '强大的算法算力',
        desc: '强大的技术团队，多项技术专利支持，运力及调度算法优化，提高运输效率，节约运输成本。'
    },
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="travelling-agent">
                <p className="wash-mobile-title">平台服务</p>
                <p className="wash-mobile-desc">PLATFORM SERVICES</p>
                <div style={{display:"flex",justifyContent: "center"}}>
                    <div style={{flex: 1,alignItems:"center",display: 'flex',marginTop: '3em',textAlign:'left'}}>
                        <img style={{margin:"0 50px"}} src={agent[0].icon}/>
                        <div>
                            <p style={{fontSize: '1.5em',color:"#000000", opacity: "0.6",margin: 0,padding:0}}>{agent[0].label}</p>
                            <p style={{fontSize: '1.2em',color:"#000000", opacity: "0.6",marginTop:'20px'}}>{agent[0].desc}</p>
                        </div>
                    </div>
                    <div style={{flex: 1,alignItems:"center",display: 'flex',marginTop: '3em',textAlign:'left'}}>
                        <img style={{margin:" 0 50px"}} src={agent[1].icon}/>
                        <div>
                            <p style={{fontSize: '1.5em',color:"#000000", opacity: "0.6",margin: 0,padding:0}}>{agent[1].label}</p>
                            <p style={{fontSize: '1.2em',color:"#000000", opacity: "0.6",marginTop:'20px'}}>{agent[1].desc}</p>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div style={{flex: 1,alignItems:"center",display: 'flex',marginTop: '3em',textAlign:'left'}}>
                        <img style={{margin:"0 50px"}} src={agent[2].icon}/>
                        <div>
                            <p style={{fontSize: '1.5em',color:"#000000", opacity: "0.6",margin: 0,padding:0}}>{agent[2].label}</p>
                            <p style={{fontSize: '1.2em',color:"#000000", opacity: "0.6",marginTop:'20px'}}>{agent[2].desc}</p>
                        </div>
                    </div>
                    <div style={{flex: 1,alignItems:"center",display: 'flex',marginTop: '3em',textAlign:'left'}}>
                        <img style={{margin:" 0 50px"}} src={agent[3].icon}/>
                        <div>
                            <p style={{fontSize: '1.5em',color:"#000000", opacity: "0.6",margin: 0,padding:0}}>{agent[3].label}</p>
                            <p style={{fontSize: '1.2em',color:"#000000", opacity: "0.6",marginTop:'20px'}}>{agent[3].desc}</p>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default home;
