import React, { Component } from "react";
import "./index.css";

const desc =
  "中孚注册资金1388.8889万，净资产1亿余元，公司共有员工80余人，在车主服务及物流运输行业深耕8年之久，" +
  "其业务涵盖物流运输与汽车后市场大部分服务，是多项车主、船主智慧服务的创造者，是国内目前领先的车主全面服务方案的提供者。" +
  "业务范围涵盖智慧加油、智慧洗车、智慧旅游、智慧养车、无车承运、无船承运、生态圈服务等。" +
  "围绕货·车·船全力打造移动互联网时代的车主船主生态圈平台，为各战略伙伴提供全面服务及系统支撑，合作共赢、同创未来。";
class home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="about-desc">
        <p className="about-desc-title">公司简介</p>
        <p className="about-desc-content">
          <span style={{ marginLeft: "2em" }} />
          {desc}
        </p>
      </div>
    );
  }
}
export default home;
