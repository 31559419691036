import React , {Component} from 'react';
import './index.css';
import banner from '../../assets/img/about_banner.jpg'


class home extends Component{
    constructor(props)
    {
        super(props);
        window.addEventListener('resize', this.resize);
        const width = document.body.clientWidth;
        const height = width * 539 / 1920 ;
        this.state = {
            height: height,
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.resize);
    }
    resize = () =>{
        const width = document.body.clientWidth;
        const height = width * 539 / 1920 ;
        this.setState({
            height: height,
        });
    }
    render() {
        return(
            <div className="join-banner" style={{height: this.state.height}}>
                <img src={banner} width="100%"/>
                <div className="about-banner-text">
                    <p>About Us</p>
                    <p>关于我们</p>
                </div>
            </div>)
    }
}
export default home;
