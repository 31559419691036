import React , {Component} from 'react';
import './index.css';
import mobile1 from '../../assets/img/washing-mobile1.png'
import mobile2 from '../../assets/img/washing-mobile2.png'
import mobile3 from '../../assets/img/washing-mobile3.png'
import mobile4 from '../../assets/img/washing-mobile4.png'

const mobile = [
    {
        icon: mobile1,
        label: '智能语音播报停车位置'
    },
    {
        icon: mobile2,
        label: '一键启动洗车机'
    },
    {
        icon: mobile3,
        label: '洗车中紧急停止'
    },
    {
        icon: mobile4,
        label: '洗车完成'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="wash-mobile">
                <p className="wash-mobile-title">智能启动</p>
                <p className="wash-mobile-desc">SMART START</p>
                <div style={{display: 'flex',justifyContent: "center"}}>
                {
                    mobile.map(item=><div style={{flex: 1}}>
                        <img src={item.icon}/>
                        <p style={{fontSize: '1.2em',color:"#404040",marginTop:'27px'}}>{item.label}</p>
                    </div>)
                }
                </div>
            </div>)
    }
}
export default home;
