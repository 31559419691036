import React , {Component} from 'react';
import './index.css';
import Module from './module'
import Header from '../../header'
import Ecology from './ecology'
import Why from './why'
import Footer from '../../footer'
import Brand from './brand'
import banner from '../../assets/img/banner/jietu2.jpg'
class home extends Component{
    constructor(props)
    {
        super(props);
        window.addEventListener('resize', this.resize);
        const width = document.body.clientWidth;
        const height = width * 1080 /1920 - 72;
        console.info(width);
        console.info(width);

        this.state = {
            videoHeight: height
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.resize);
    }
    resize = () =>{
        const width = document.body.clientWidth;
        const height = width * 1080 /1920 -72;
        this.setState({
            videoHeight: height
        })
    }
    render() {
        return(
            <div>
                <Header location = "home" />
                <div className="banner-plat" style={{height: this.state.videoHeight + 'px' ,margin: 0,lineHeight: this.state.videoHeight + 'px'}}>
                    <div className='banner-text'>
                        <p>构建智慧场景 <span style={{marginLeft: '.5em'}}>打造共赢生态</span></p>
                    </div>
                    <video className="home-banner-video" poster={banner} autoPlay="autoPlay" loop="loop" muted>
                        <source type="video/mp4" src="https://ugc.cheyuu.com/data/aaaa.mp4"/>
                    </video>
                </div>
                <Brand/>
                <Module/>
                <Ecology/>
                <Why/>
                <Footer/>
            </div>)
    }
}
export default home;
