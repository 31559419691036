import React , {Component} from 'react';
import scene1 from '../../assets/img/home_scene_1.jpg'
import scene2 from '../../assets/img/home_scene_2.jpg'
import scene3 from '../../assets/img/home_scene_3.jpg'
import scene4 from '../../assets/img/home_scene_4.jpg'

import './index.css';
const service = [
    {
        key: 'oil',
        img: scene1,
        title: "智慧加油站",
        desc:["信息化管理","智能化销售","加油站业务专家"]
    },
    {
        key: 'wash',
        img: scene2,
        title: "洗车机器人",
        desc: ["节能环保","省时省力","无人值守洗车机器人"]
    },
    {
        key: 'transfer',
        img: scene3,
        title: "智慧物流",
        desc: ["信息化可视化","一站式多式联运","智能化物流平台"]
    },
    {
        key: 'finance',
        img: scene4,
        title: "供应链金融",
        desc: ["区块链技术","风控体系","运输服务及消费"]
    },
]
class Module extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
            key: "oil"
        }
    }
    onServiceSelect = (key) =>{
        this.setState({key: key})
    }
    render() {
        const current = service.find(item => item.key == this.state.key);
        return(
            <div className="module">
                <p className="module-title">智慧场景</p>
                {
                    service.map((item ,index) =><div style={{display: "inline-block"}}>
                        <span onClick={this.onServiceSelect.bind(null,item.key)} className={"module-part-title" + (item.key == this.state.key ? " module-part-title-select" : "")}>{item.title}</span>
                        {
                            index == service.length - 1 ? "" : <div className="module-title-circle"/>
                        }
                    </div>)
                }
                <div className="module-content">
                    <img src={current.img}/>
                    <div style={{marginLeft: "6em",textAlign:'left'}}>
                        <p className="module-content-title">{current.title}</p>
                        {
                            current.desc.map(item=><p className="module-content-desc">{item}</p>)
                        }
                    </div>
                </div>
            </div>)
    }
}
export default Module;
