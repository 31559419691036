import React , {Component} from 'react';
import './index.css';
import cheyoo from "../assets/img/qr_cheyoo.jpg"
import transfer from "../assets/img/qr_transfer.jpg"
import logo from '../assets/img/logo.png'

const links = [
    {
        name: "企业简介",
        url: 'about'
    },
    {
        name: "车友网",
        url: 'cheyoo'
    },
    {
        name: "联运中国",
        url: 'union'
    },
    {
        name: "加入我们",
        url: 'joinus'
    },
]
class header extends Component{
    constructor(props)
    {
        super(props);
    }
    navigator = (url) =>{
        window.location.href = '/' + url;
    }
    render() {
        return (
            <div className="footer">
                <div className="footer-content">
                    <img src= {logo} />
                    <div className="footer-button">
                        {
                            links.map(item=><p onClick={this.navigator.bind(null,item.url)}>{item.name}</p>)
                        }
                    </div>
                    <div style={{flex: 1}}/>
                    <img src={cheyoo} className="footer-qr-code"/>
                    <img src={transfer} className="footer-qr-code"/>
                    <div className="footer-content-line">
                        <p className="footer-button-title">联系我们</p>
                        {/* <p className="footer-custom-service">400-6326-888</p>
                        <p className="footer-contact-phone">027-58905705</p> */}
                    </div>
                </div>
                <a href ="http://beian.miit.gov.cn/" className="footer-button">鄂ICP备2021000446号-2 | ©2021中孚投资</a>
            </div>
        );
    }
}
export default header;

