import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/pay-strength1.png'
import strength2 from '../../assets/img/pay-strength2.png'
import strength3 from '../../assets/img/pay-strength3.png'
import strength4 from '../../assets/img/pay-strength4.png'

const strength = [
    {
        icon: strength1,
        label: '无操作',
        desc: '支付零操作'
    },
    {
        icon: strength2,
        label: '无接触',
        desc: '消费无接触'
    },
    {
        icon: strength3,
        label: '多通道',
        desc: '多扣款通道支持'
    },
    {
        icon: strength4,
        label: '零风险',
        desc: '超强风控保驾护航'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="pay-strength">
                {
                    strength.map(item=>
                    <div style={{flex: 1}}>
                        <img src={item.icon}/>
                        <p style={{fontSize:'1.6em',fontWeight:'bold',color:'#000000',opacity:'0.6'}}>{item.label}</p>
                        <p style={{fontSize:'1.2em',color:'#000000',opacity:'0.6'}}>{item.desc}</p>
                    </div>)
                }
            </div>)
    }
}
export default home;
