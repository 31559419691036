import React , {Component} from 'react';
import './index.css';
import Header from '../../header'
import Banner from './banner'
import Strength from './strength'
import Footer from '../../footer'
import Agent from './agent'
import Core from './core'
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div>
                <Header style = "transparent_black" />
                <Banner/>
                <Strength/>
                <Core/>
                <Agent/>
                <Footer/>
            </div>)
    }
}
export default home;
