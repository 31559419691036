import React , {Component} from 'react';
import banner from '../../assets/img/washing-banner.jpg'
import './index.css';
class Module extends Component{
    constructor(props)
    {
        super(props);
        window.addEventListener('resize', this.resize);
        const width = document.body.clientWidth;
        const height = width * 538 / 1920;
        this.state = {
            height: height,
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.resize);
    }
    resize = () =>{
        const width = document.body.clientWidth;
        const height = width * 538 / 1920 ;
        this.setState({
            height: height,
        });
    }
    render() {
        return(
            <div className="wash-banner" style={{height: this.state.height}}>
                <img src={banner} width="100%"/>
                <div style={{display: 'flex',flexDirection:"column",height: this.state.height,marginLeft: "12%",marginRight:'12%'}}>
                    <div style={{flex: 1}}/>
                    <p className="wash-banner-title">自动洗车</p>
                    <p className="wash-banner-desc">提供完整的全自动洗车方案，让洗车智能化、管理数字化、运营网络化。</p>
                    <div style={{flex: 1}}/>
                </div>
            </div>)
    }
}
export default Module;
