const emphasis = param =>{
    console.info(param)
    const province = data.find(item => param.name == item.name);
    return '{title|' + param.name + '}'
        + '\n{text|'
        + "服务网点：" + ( province ? province.value  + "+" : '暂无')  + '}'
}
const toolTip = param =>{
    const province = data.find(item => param.name == item.name);
    return '{title|' + param.name + '}'
        + '\n{text|'
        + "服务网点：" + ( province ? province.count : '暂无')  + '}'
}
const getRegions = () =>{
    const regions = new Array();
    data.map(item => regions.push({
        name: item.name,
        label:{
            formatter: emphasis,
            rich:style.popup.rich,
        },
        emphasis:{
            label: style.popup
        }
    }))
    return regions;
}
const markdowns = () =>{
    const markdown = new Array();
    data.map(item => markdown.push([...item.location,item.value,item.name]));
    return markdown;
}
const data = [
    {
        name: '湖北省',
        value: 800,
        location: [114.31,30.52]
    },
    {
        name: '贵州省',
        value: '300',
        location: [106.71,26.57]
    },
    {
        name: '青海省',
        value: '200',
        location: [101.74,36.56]
    },
    {
        name: '四川省',
        value: '150',
        location: [104.06,30.67]
    },
    {
        name: '新疆维吾尔自治区',
        value: "80",
        location: [87.68,43.77]
    },
    {
        name: '宁夏回族自治区',
        value: "300",
        location: [106.27,38.47]
    }
]

const style = {
    popup:{
        show: true,
        color: '#FFFFFF',
        backgroundColor: 'rgba(0,0,0,0.4)',
        padding: 10,
        align: 'left',
        lineHeight:'30',
        rich:{
            title:{
                fontSize: 16,
            },
            text:{
                fontSize: 20
            }
        }
    },
    label:{
        show:false,
        emphasis: {
            show: false
        }
    },
    area:{
        normal: {
            areaColor: "#dfe0e5",
            borderColor: "#FFFFFF",
        },
        emphasis:{
            areaColor: 'rgba(56,180,176,0.5)'
        }
    },
    markdown:{
        borderColor: 'rgba(0,0,0,0.2)',
        borderWidth:8,
        color: "#50a3ba"
    },

}
const geo = {
    regions: getRegions(),
    itemStyle: style.area.normal,
    emphasis: {
        itemStyle: style.area.emphasis,
    },
    label:style.label,
    map: 'china',
    roam: false,
    zoom: 1.2,
}
const series = {
    type: 'scatter',
    coordinateSystem: 'geo',
    symbol:'circle',
    symbolSize: 8,
    data: markdowns(),
    itemStyle: style.markdown,
}
export default {
    geo: geo,
    series: [series]
};
