import React , {Component} from 'react';
import './index.css';
import Header from '../../header'
import Footer from '../../footer'
import Banner from './banner'
import Map from './map'
import Strength from "./strength";
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div>
                <Header location = "cheyoo" style = "transparent_black" />
                <Banner/>
                <Map/>
                <Strength/>
                <Footer/>
            </div>)
    }
}
export default home;
