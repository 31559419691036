import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/union_strength_1.jpg'
import strength2 from '../../assets/img/union_strength_2.jpg'
import strength3 from '../../assets/img/union_strength_3.jpg'
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="union-strength">
                <div className="union-strength-plat">
                    <div className="union-strength-plat-img-top" style={{flex: 1}}>
                        <img src={strength1}/>
                    </div>
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">Intelligent</p>
                        <p className="union-strength-plat-title">智能调度</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-bg-sub-text">Scheduling</p>
                            <p className="union-strength-plat-subtitle">动态配置、一单到底</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">可插拔式的配置化流程</p>
                        <p className="union-strength-plat-desc">便捷高效、一键分发指派</p>
                    </div>
                </div>

                <div className="union-strength-plat">
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">Visualization</p>
                        <p className="union-strength-plat-title">信息可视化</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-subtitle">五流统一、动态预警</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">数据流、票据流、证据流、单据流、资金流规范统一</p>
                        <p className="union-strength-plat-desc">APP、微信小程序、Web网页等多入口协同工作</p>
                        <p className="union-strength-plat-desc">定位轨迹、地理围栏、车/船载监控为每一笔订单保驾护航</p>
                    </div>
                    <div className="union-strength-plat-img-right" style={{flex: 1}}>
                        <img src={strength2}/>
                    </div>
                </div>

                <div className="union-strength-plat">
                    <div className="union-strength-plat-img-bottom" style={{flex: 1}}>
                        <img src={strength3}/>
                    </div>
                    <div style={{flex: 1}} className="union-strength-plat-right">
                        <p className="union-strength-plat-bg-text">Financial</p>
                        <p className="union-strength-plat-title">金融赋能</p>
                        <div className="union-strength-plat-line"/>
                        <div style={{position: 'relative'}}>
                            <p className="union-strength-plat-bg-sub-text">Empowerment</p>
                            <p className="union-strength-plat-subtitle">真实可靠、超强风控</p>
                        </div>
                        <div style={{flex: 1}}/>
                        <p className="union-strength-plat-desc">基于区块链技术、确保数据的真实性、可追溯性</p>
                        <p className="union-strength-plat-desc">基于平台风控模式、提供运输及生活服务，解决司机/船主周转问题</p>
                    </div>
                </div>
            </div>)
    }
}
export default home;
