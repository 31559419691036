import React , {Component} from 'react';
import './index.css';
import effect1 from '../../assets/img/gas-effect1.png'
import effect2 from '../../assets/img/gas-effect2.png'
import effect3 from '../../assets/img/gas-effect3.png'
const effects = [
    {
        icon:effect1,
        label:"工作效率增加",
        desc:"试验站点采用智慧加油站解决方案三个月后，单比交易平均耗时提升42秒，在业务繁忙，出现车辆排队时，单车加油时间缩短19.5%"
    },
    {
        icon:effect2,
        label:"用户粘性增加",
        desc:"试验站点采用智慧加油站解决方案三个月后，私家车单车加油频次由8次提升至14.1次，客单价由170元提升至203元"
    },
    {
        icon:effect3,
        label:"销量提升",
        desc:"试验站点采用智慧加油站解决方案三个月后，油品销量同比提升48.5%，环比提升37.5%，便利店商品销售同比提升72.1%，环比提升51.2%，效果显著"
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="gas-effect">
                <p className="wash-mobile-title">站点改造</p>
                <p className="wash-mobile-desc">STATION RENOVATION</p>
                <div>
                {
                    effects.map(item=><div style={{display:'flex',marginTop:'3em',justifyContent: "center"}}>
                        <img src={item.icon} style={{margin: '0 3em'}}/>
                        <div style={{margin: '0 2em',textAlign:'left'}}>
                            <p style={{color:"#000000",fontSize:'1.6em',fontWeight:'bold',opacity:'0.6'}}>{item.label}</p>
                            <p style={{color:"#000000",fontSize:'1.2em',opacity:'0.6'}}>{item.desc}</p>
                        </div>
                    </div>)
                }
                </div>
            </div>)
    }
}
export default home;
