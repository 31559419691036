import React from 'react';
import Cheyoo from './pages/cheyoo'
import Home from './pages/home'
import Union from './pages/union'
import Join from './pages/join'
import About from './pages/about'
import Wash from './pages/wash'
import Gas from './pages/gas'
import Pay from './pages/pay'
import Travelling from './pages/travelling'
import { BrowserRouter as Router , Route} from 'react-router-dom';

function router() {
  return (
    <Router>
        <Route path="/" component = {Home} exact/>
        <Route path="/home" component = {Home}/>
      <Route path="/cheyoo" component = {Cheyoo}/>
      <Route path="/union" component = {Union}/>
      <Route path="/joinus" component = {Join}/>
      <Route path="/about" component ={About}/>
      <Route path="/product/washing" component ={Wash}/>
      <Route path="/product/gas" component ={Gas}/>
      <Route path="/product/pay" component ={Pay}/>
        <Route path="/product/travelling" component ={Travelling}/>

    </Router>
  );
}

export default router;
