import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/travelling-strength1.png'
import strength2 from '../../assets/img/travelling-strength2.png'
import strength3 from '../../assets/img/travelling-strength3.png'
import strength4 from '../../assets/img/travelling-strength4.png'

const strength = [
    {
        icon: strength1,
        label: '信息化'
    },
    {
        icon: strength2,
        label: '网络化'
    },
    {
        icon: strength3,
        label: '平台化'
    },
    {
        icon: strength4,
        label: '智能化'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="travelling-strength">
                {
                    strength.map(item=><div style={{flex: 1}}>
                        <img src= {item.icon}/>
                        <p>{item.label}</p>
                    </div>)
                }
            </div>
            )
    }
}
export default home;
