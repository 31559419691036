import React , {Component} from 'react';
import './index.css';
import type1 from '../../assets/img/pay-type1.jpg'
import type2 from '../../assets/img/pay-type2.jpg'
import type3 from '../../assets/img/pay-type3.jpg'

class home extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
            hoverIndex: -1
        }
    }
    render() {
        return(
            <div className="pay-type">
                <div>
                    <div className="pay-type-row firstBg" >
                        <div className="firstBox">
                            <p className="fistBgText">识别方式</p>
                            <p className='horizontalLine'></p>
                            <p className="txt">Identification</p>
                            <p className="txt">mode</p>
                        </div>
                    </div>
                    <div className="pay-type-row">
                        <img src={type1} alt=""/>
                        <div className="pay-type-box">
                            <p className="pay-type-title">ETC</p>
                            <div className="pay-type-title-split"/>
                            <p className="pay-type-desc">联动车载ETC中的OBU单元，精准识别车辆ETC，确认车主身份。</p>
                        </div>
                    </div>
                    <div className="pay-type-row">
                        <div className="pay-type-box">
                            <p className="pay-type-title">车牌</p>
                            <div className="pay-type-title-split"/>
                            <p className="pay-type-desc">根据消费环境，辅以多终端（手持、吊顶、立柱）兼容、多算法支撑、完美识别车辆车牌及用户消费意图。</p>
                        </div>
                        <img src={type2}/>
                    </div>
                    <div className="pay-type-row">
                        <img src={type3}/>
                        <div className="pay-type-box">
                            <p className="pay-type-title">人脸识别</p>
                            <div className="pay-type-title-split"/>
                            <p className="pay-type-desc">配合人脸识别组件，通过人脸确认车主身份。</p>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default home;
