import React , {Component} from 'react';
import './index.css';
import core1 from '../../assets/img/travelling-core1.jpg'
import core2 from '../../assets/img/travelling-core2.jpg'
import core3 from '../../assets/img/travelling-core3.jpg'
import core4 from '../../assets/img/travelling-core4.jpg'

const core = [
    {
        icon: core1,
        label: '运力优势',
        desc: '中长短途 覆盖全国'
    },
    {
        icon: core2,
        label: '机制优势',
        desc: '动态匹配 智慧工作流'
    },
    {
        icon: core3,
        label: '人才优势',
        desc: '全供应链覆盖 多行业专家'
    },
    {
        icon: core4,
        label: '技术优势',
        desc: '大数据 区块链'
    },
]
class home extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
            hoverIndex: -1
        }
    }
    onEnter = (index) =>{
        this.setState({
            hoverIndex: index
        })
    }

    onLeave = (index) =>{
        this.setState({
            hoverIndex: -1
        })
    }
    render() {
        return(
            <div className="travelling-core">
                <p className="wash-mobile-title">核心优势</p>
                <p className="wash-mobile-desc">CORE ADVANTAGES</p>
                <div>
                {
                    core.map((item,index)=><div
                        onMouseEnter={this.onEnter.bind(null,index)}
                        onMouseLeave={this.onLeave.bind(null,index)}
                        style={{backgroundImage: "url(" + item.icon + ")",
                            width: 300,height:440,backgroundRepeat: 'no-repeat'}}>
                            <div style={{width:"100%",height:"100%",
                                display:'flex',
                                textAlign:'left',
                                alignItems:"start",justifyContent: 'flex-end',flexDirection:"column",
                                background: index == this.state.hoverIndex?'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1))':""}}>
                            <p className="travelling-core-title">{item.label}</p>
                            {index == this.state.hoverIndex?
                                <p className="travelling-core-desc">{item.desc}</p>:""
                            }
                            </div>
                        </div>)
                }
                </div>
            </div>)
    }
}
export default home;
