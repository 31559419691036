import React , {Component} from 'react';
import './index.css';
import map from '../../assets/img/contact.png'
const info = [
    {
        name:"地址",
        desc:"武汉市江汉区世界贸易大厦22层3-5室"
    },
    // {
    //     name:"邮编",
    //     desc:"430000"
    // },
    // {
    //     name:"电话",
    //     desc:"027-58905705"
    // },
    // {
    //     name:"传真",
    //     desc:"027-58905718"
    // }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div style={{background:'#FFF',padding: '5em 10%'}}>
                <p className="about-desc-title">联系我们</p>
                <img src={map} width="100%"/>
                <div style={{marginTop:'3em'}}>
                    {info.map(item =><div>
                        <span className="join-contact-name">{item.name}</span>
                        <span className="join-contact-info">{item.desc}</span>
                    </div>)}
                </div>
            </div>)
    }
}
export default home;
