import React , {Component} from 'react';
import './index.css';
import agent1 from '../../assets/img/washing-agent1.png'
import agent2 from '../../assets/img/washing-agent2.png'
import agent3 from '../../assets/img/washing-agent3.png'
const mobile = [
    {
        icon: agent1,
        label: '车机互动',
        desc: '车辆进入地图范围，智能提醒洗车需求'
    },
    {
        icon: agent2,
        label: '云端监控',
        desc: '实时监测，远程控制与恢复'
    },
    {
        icon: agent3,
        label: '远程运维',
        desc: '日常运维远程操作，系统控制排水排气'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="wash-agent">
                <p className="wash-mobile-title">智能物联</p>
                <p className="wash-mobile-desc">INTELLIGENT IOT</p>
                <div style={{display: 'flex',justifyContent: "center"}}>
                    {
                        mobile.map(item=><div style={{flex: 1}}>
                            <img src={item.icon}/>
                            <p style={{fontSize: '1.2em',color:"#000000", opacity: "0.6",marginTop:'26px'}}>{item.label}</p>
                            <p style={{fontSize: '1em',color:"#000000", opacity: "0.6",marginTop:'20px'}}>{item.desc}</p>
                        </div>)
                    }
                </div>
            </div>)
    }
}
export default home;
