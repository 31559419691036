import React, { Component } from "react";
import "./index.css";
import arrow from "../../assets/img/timeline_arrow.png";
const desc =
  "中孚注册资金1388.8889万，净资产1亿余元，公司共有员工80余人，在车主服务及物流运输行业深耕8年之久，" +
  "其业务涵盖物流运输与汽车后市场大部分服务，是多项车主、船主智慧服务的创造者，是国内目前领先的车主全面服务方案的提供者。" +
  "业务范围涵盖智慧加油、智慧洗车、智慧旅游、智慧养车、无车承运、无船承运、生态圈服务等。" +
  "围绕货·车·船全力打造移动互联网时代的车主船主生态圈平台，为各战略伙伴提供全面服务及系统支撑，合作共赢、同创未来。";
class home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="about-timeline">
        <p className="about-timeline-title">发展历程</p>
        <div className="about-timeline-line-title">
          <div style={{ flex: 1 }} className="about-timeline-line-year">
            <p>Future</p>
            <div className="about-timeline-line-arrow">
              <div />
              <div />
            </div>
          </div>
          <div style={{ flex: 2 }} className="about-timeline-line-year">
            <p>2019.</p>
            <span />
          </div>
          <div style={{ flex: 2 }} className="about-timeline-line-year">
            <p>2018.</p>
            <span />
          </div>
          <div style={{ flex: 2 }} className="about-timeline-line-year">
            <p>2017.</p>
            <span />
          </div>
          <div style={{ flex: 2 }} className="about-timeline-line-year">
            <p>2015.</p>
            <span />
          </div>
        </div>
        <div className="about-timeline-line">
          <div style={{ flex: 1 }} />
          <div style={{ flex: 2 }}>
            <p className="about-timeline-line-month">9月/Sept</p>
            <p className="about-timeline-line-desc">水路运输平台上线</p>
          </div>
          <div style={{ flex: 2 }}>
            <p className="about-timeline-line-month">4月/Apr</p>
            <p className="about-timeline-line-desc">车友旅游用户数突破30万</p>
            <p className="about-timeline-line-month">8月/Aug</p>
            <p className="about-timeline-line-desc">公路运输平台上线</p>
          </div>
          <div style={{ flex: 2 }}>
            <p className="about-timeline-line-month">3月/Mar</p>
            <p className="about-timeline-line-desc">
              智慧加油站项目累计交易数突破10亿元
            </p>
            <p className="about-timeline-line-month">5月/May</p>
            <p className="about-timeline-line-desc">
              车友旅游，车友保养项目上线
            </p>
          </div>
          <div style={{ flex: 2 }}>
            <p className="about-timeline-line-month">1月/Jan</p>
            <p className="about-timeline-line-desc">
              国内首个基于加油站的一键加油系统上线
            </p>
            <p className="about-timeline-line-month">11月/Nov</p>
            <p className="about-timeline-line-desc">
              一键加油系统加盟油站超150座，服务用户突破100万
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default home;
