import React , {Component} from 'react';
import './index.css';
import agent1 from '../../assets/img/gas-agent1.png'
import agent2 from '../../assets/img/gas-agent2.png'
import agent3 from '../../assets/img/gas-agent3.png'

class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="gas-agent">
                <p className="wash-mobile-title">多端协同</p>
                <p className="wash-mobile-desc">MULTI TERMINAL COLLABORATION</p>
                <div style={{display: 'flex',justifyContent: "center"}}>
                    <div style={{flex: 1}}>
                        <img src={agent1}/>
                        <p className="gas-agent-title">用户</p>
                        <p className="gas-agent-desc">APP</p>
                        <p className="gas-agent-desc">微信公众号/小程序</p>
                        <p className="gas-agent-desc">支付宝生活号</p>
                    </div>
                    <div style={{flex: 1,marginTop:100}}>
                        <img src={agent2}/>
                        <p className='gas-agent-title'>站点</p>
                        <p className="gas-agent-desc">智能集线器</p>
                        <p className="gas-agent-desc">站级中控台</p>
                    </div>
                    <div style={{flex: 1}}>
                        <img src={agent3}/>
                        <p className="gas-agent-title">管理套件</p>
                        <p className="gas-agent-desc">商户端APP</p>
                        <p className="gas-agent-desc">商户端小程序</p>
                        <p className="gas-agent-desc">钉钉智慧工作流</p>
                    </div>
                </div>
            </div>)
    }
}
export default home;
