/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import './index.css';
import logo from '../assets/img/logo.png'
const title = [
    {
        title: "首页",
        key: "home"
    },
    {
        title: "产品服务",
        key: "product",
        children: [
            {
                title: "智慧加油站",
                key: "gas",
            },
            {
                title: "无感支付",
                key: "pay",
            },
            {
                title: "自动洗车",
                key: "washing",
            },
            {
                title: "智慧物流",
                key: "travelling",
            },
        ]
    },
    {
        title: "关于中孚",
        key: "about"
    },
    {
        title: "加入我们",
        key: "joinus"
    }
]
class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        }
    }
    navigator = (url) => {
        window.location.href = '/' + url;
    }
    hover = () => {
        this.setState({
            showMore: true
        })
    }
    leave = () => {
        this.setState({
            showMore: false
        })
    }
    multiMenu = (item) =>
        <div onMouseEnter={this.hover} onMouseLeave={this.leave}>
            <div className={"header-menu"} >
                {item.title}<i className="iconfont icon-triangle-bottom" />
            </div>
            {this.state.showMore ?
                <div className="header-multi-menu">
                    {
                        item.children.map(sub => <p onClick={this.navigator.bind(null,
                            item.key + '/' + sub.key)}>{sub.title}</p>)
                    }
                </div> : ""
            }
        </div>
    menu = (item, location) => <div onClick={this.navigator.bind(null, item.key)} className={item.key == location ? "header-menu header-menu-active" : "header-menu"}>
        {item.title}
    </div>
    render() {
        const { location, style } = this.props;
        return (
            <div className={"header" + (style ? " " + style : "")}>
                <img src={logo} style={{width:"110px"}} />
                <div className="header-menu-space" />
                {
                    title.map(item => item.children && item.children.length > 0 ? this.multiMenu(item, style) : this.menu(item, location))
                }
            </div>
        );
    }
}
export default header;