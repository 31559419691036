import React , {Component} from 'react';
import './index.css';
import agent1 from '../../assets/img/pay-agent1.jpg'
import agent2 from '../../assets/img/pay-agent2.jpg'
import agent3 from '../../assets/img/pay-agent3.jpg'
import agent4 from '../../assets/img/pay-agent4.jpg'

class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="pay-agent">
                <p className="wash-mobile-title">多支付通道兼容</p>
                <p className="wash-mobile-desc">MULTI PAYMENT CHANNEL COMPATIBILITY</p>
                <div className="pay-agent-circle" >
                    <div className="pay-agent-row">
                        <p>银行渠道</p>
                        <img src={agent1}/>
                        <div style={{flex: 1}}/>
                        <img src={agent2}/>
                        <p>异业伙伴</p>
                    </div>
                    <div style={{flex: 1}}/>
                    <div className="pay-agent-center">
                        <p>多通道兼容</p>
                    </div>
                    <div className="pay-agent-row">
                        <p>微信/支付宝</p>
                        <img src={agent3}/>
                        <div style={{flex: 1}}/>
                        <img src={agent4}/>
                        <p>油站IC卡</p>
                    </div>
                </div>
                {/*<div style={{width: height,height: height}}>*/}
                    {/*<div className="pay-agent-row">*/}
                        {/*<p>银行渠道</p>*/}
                        {/*<img src={agent1}/>*/}
                        {/*<div style={{flex: 1}}/>*/}
                        {/*<img src={agent2}/>*/}
                        {/*<p>异业伙伴</p>*/}
                    {/*</div>*/}
                    {/*<div className="pay-agent-row">*/}
                        {/*<p>微信/支付宝</p>*/}
                        {/*<img src={agent3}/>*/}
                        {/*<div style={{flex: 1}}/>*/}
                        {/*<img src={agent4}/>*/}
                        {/*<p>油站IC卡</p>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>)
    }
}
export default home;
