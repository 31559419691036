import React, { Component } from "react";
import banner from "../../assets/img/travelling-banner.jpg";
import "./index.css";
class Module extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("resize", this.resize);
    const width = document.body.clientWidth;
    const height = (width * 538) / 1920;
    this.state = {
      height: height,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  resize = () => {
    const width = document.body.clientWidth;
    const height = (width * 538) / 1920;
    this.setState({
      height: height,
    });
  };
  render() {
    return (
      <div className="wash-banner" style={{ height: this.state.height }}>
        <img src={banner} width="100%" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: this.state.height,
            marginLeft: "12%",
            marginRight: "12%",
          }}
        >
          <div style={{ flex: 1 }} />
          <p className="wash-banner-title">智慧物流</p>
          <p className="wash-banner-desc">
            可视化一站式智慧物流平台，让物流运作流程高效智能化、有效利用物流资源
          </p>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  }
}
export default Module;
