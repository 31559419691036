import React , {Component} from 'react';
import './index.css';
import strength1 from '../../assets/img/washing-strength1.png'
import strength2 from '../../assets/img/washing-strength2.png'
import strength3 from '../../assets/img/washing-strength3.png'
import strength4 from '../../assets/img/washing-strength4.png'

const strength = [
    {
        icon: strength1,
        label: '洗车智能化'
    },
    {
        icon: strength2,
        label: '服务优质化'
    },
    {
        icon: strength3,
        label: '管理数字化'
    },
    {
        icon: strength4,
        label: '运营网络化'
    }
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div className="wash-strength">
                <div className="wash-strength-border-outer">
                    <div className="wash-strength-border-inner">
                        {
                            strength.map(item=><div style={{flex: 1}}>
                                <img src= {item.icon}/>
                                <p>{item.label}</p>
                            </div>)
                        }
                    </div>
                </div>
            </div>)
    }
}
export default home;
