import React , {Component} from 'react';
import './index.css';
import Header from '../../header'
import Footer from '../../footer'
import Banner from './banner'
import Desc from './desc'
import Timeline from './timeline'
import Contact from './contact'
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div>
                <Header location = "about" style = "transparent_black" />
                <Banner/>
                <Desc/>
                <Timeline/>
                <Contact/>
                <Footer/>
            </div>)
    }
}
export default home;
