import React , {Component} from 'react';
import './index.css';

const jobs = [
    {
        title:"城市经理",
        desc: [
            "工作职责:",
            "1、负责市场的团队建设、培训、陪访，提升团队的服务力;",
            "2、负责分管区域的业务拓展工作，拟订并落实销售计划，管理、维护、开发商家，完成所负责区域的销售目标；",
            "3、建立、维护所辖区域的客户关系，进行商务谈判，提供解决方案，对潜在客户进行定期跟踪，定期提交销售进度报告；",
            "4、收集、整理、反馈区域市场数据及分析报告，并对公司提出有价值的改善方案，对公司的新项目开发提供市场支持，提出销售策略、销售政策的修订建议。",
            "岗位要求：",
            "1、大专以上学历，相关工作经验3年以上，有1年以上的团队管理经验；",
            "2、对于O2O以及汽车后市场有独到的理解。有较强的解决问题和沟通协调能力；",
            "3、具有良好的逻辑分析能力能够单独的制定销售策略；",
            "4、具备很强的人际关系交往能力和应变能力；",
            "5、内心强大有一定的抗压性。",
        ]
    },
    {
        title:"运营主管",
        desc: [
            "工作职责:",
            "1、制定活动计划，策划线上线下活动方案，撰写活动开发需求；",
            "2、负责制定提升产品活跃度的营销活动，以及活动的相关宣传需求沟通以及资料提供；",
            "3、负责活动的组织及实施，全面把控活动质量，并对活动效果进行分析；",
            "4、及时处理活动突发事件",
            "5、负责平台阶段性的传播并策划文案；",
            "6、挖掘老客户消费能力，提升平台客户粘性。",
            "岗位要求:",
            "1、1年以上微信、APP运营策划工作经验。",
            "2、对目标产品类型有深刻的理解；",
            "3、要求具有较强的市场调研能力，能捕捉社会热点；",
            "4、要求有一定的文字组织能力，语言表达和沟通能力；",
            "5、具有良好的分析能力和逻辑思维能力，创新的思想，愿意接受新的挑战；",
        ]
    },
    {
        title:"客服专员",
        desc: [
            "工作职责:",
            "1、负责接听客户电话,准确理解并引导客户需求，为客户提供快速、准确的解答服务；",
            "2、合理安排服务资源，投诉处理、客户咨询等相关业务，记录并及时反馈客户信息；",
            "3、负责建立并维护良好的客户关系，并按照规范的服务流程及时有效的为客户提供耐心周到的服务，致力于建立客户对公司品牌的忠诚度。",
            "岗位要求:",
            "1、普通话标准，无口音，思维清晰，善于表达自己；",
            "2、具备销售意识，能承受工作压力，能有效合理安排时间并保证既定目标的实现；",
            "3、具有良好的与客户沟通、交流能力，懂得倾听能敏锐捕捉客户心理活动并加以引导;",
            "4、有汽车后服务市场行业从业者优先。"
        ]
    },
    {
        title:"php开发工程师",
        desc: [
            "工作职责:",
            "1、负责车友网项目产品的研发",
            "2、微信、APP接口开发",
            "3、参与后台管理系统开发、维护和管理；",
            "4、遵循开发管理流程，编写和完善相关文档。",
            "5、维护现有的应用产品并提出改良方案。",
            "岗位要求:",
            "1、3年以上PHP开发经验，扎实的PHP开发能力和良好的编码习惯；",
            "2、熟悉一种以上PHP MVC开发框架，YII优先；",
            "3、熟悉MySQL数据库操作，熟悉SQL语句调优；",
            "4、熟悉LNMP架构，能进行LNMP网站架构的系统配置、安全、性能的调优；",
            "5、有电子商务，微信，支付等相关开发经验优先；",
            "6、工作认真仔细，有责任心，具备良好的团队合作精神和主动学习的能力。"
        ]
    },
    {
        title:"UI设计师",
        desc: [
            "工作职责:",
            "1、参与产品整体设计，规划产品的UI设计方案；",
            "2、与产品经理沟通协作。完善产品视觉化内容的设计，提出用户体验改进意见和建议，持续优化产品；",
            "3、与前端技术工程师协作，高质量完成工作。",
            "4、对各种相关软件的设计和应用提出构思新颖、有高度吸引力的创意设计；",
            "5、维护现有的应用产品并提出改良方案。",
            "岗位要求:",
            "1、视觉设计、平面设计、工业设计相关专业；",
            "2、对交互及前端有一定的了解，保证产出物适合实际使用；",
            "3、具有良好的创新思维，优秀的审美和实现能力；",
            "4、能提出对产品有价值的想法，通过沟通与共享实现；",
            "5、对细节的追求，对个人想法的坚持与权衡；",
            "6、简历请附作品（需有网页或者App设计作品）"
        ]
    },
    {
        title:"产品经理助理",
        desc: [
            "工作职责:",
            "1、分析移动互联网产业发展的热点和趋势，开展产品市场需求调研，产品创意和可行性分析，同类产品市场资料的收集；",
            "2、负责移动互联网客户端的创新产品思路、功能规划设计；",
            "3、根据产品需求，撰写详细的产品设计文档和原型设计文档；",
            "4、引导完成产品的界面、功能、流程设计，协调开发人员进行开发工作，推动及协调产品的开发进度，把控项目质量；",
            "5、充分了解用户需求，收集用户反馈，分析用户行为及需求，对产品进行持续的优化和用户体验改进；",
            "6、梳理移动客户端产品开发流程，监控竞争对手的移动产品策略，进行移动互联网产业的市场研究和分析，保证产品的竞争",
            "岗位要求:",
            "1、大专及以上学历；",
            "2、1年以上软件产品策划工作经验；",
            "3、有一定的美术/UI设计经验；",
            "4、对移动互联网相关产品有较深入的了解；",
            "5、具有较强的组织和协调能力；",
            "6、工作富有激情，热爱设计，有创意想法，具有挑战和学习精神，良好的领会能力，沟通能力，及注重团队配合和全局意识；",
            "7、一定数量和质量的作品，提交简历时请附上代表作品；"
        ]
    },
]
class home extends Component{
    constructor(props)
    {
        super(props);
    }
    render() {
        return(
            <div>
                {
                    jobs.map(item=><div className="jobs-title">
                        <p className="jobs-title-name">{item.title}</p>
                        {
                            item.desc.map(desc=><p className="join-title-desc">{desc}</p>)
                        }
                    </div>)
                }
            </div>)
    }
}
export default home;
