import React, { Component } from 'react';
import './index.css';
import partner1 from '../../assets/img/partner_1.jpg'
import partner2 from '../../assets/img/partner_2.jpg'
import partner3 from '../../assets/img/partner_3.jpg'
import partner4 from '../../assets/img/partner_4.jpg'
import partner5 from '../../assets/img/partner_5.jpg'
import partner6 from '../../assets/img/partner_6.jpg'
import partner7 from '../../assets/img/partner_7.jpg'
import partner8 from '../../assets/img/partner_8.jpg'

class Why extends Component {
    constructor(){
        super()
        this.box=React.createRef()
        this.state={
            content1 :
                {
                    count: 3,
                    nub:0,
                    amount: '个',
                    name: '研发中心'
                },
                content2 : {
                    nub:0,
                    count: 1000,
                    amount: '万名',
                    name: '服务用户'
                },
                content3 :   {
                    nub:0,
                    count: 8000,
                    amount: '万单',
                    name: '累计订单'
                },
                content4 :    {
                    count: 80,
                    nub:0,
                    amount: '个',
                    name: '合作伙伴'
                }

        }
    }
    componentWillMount(){
        window.onscroll=()=>{
            let client = this.box.current.getBoundingClientRect()
            if (client.top<(window.innerHeight-20)) {
                this.nuberScrollShow('content1',1)
                this.nuberScrollShow('content2',33)
                this.nuberScrollShow('content3',363)
                this.nuberScrollShow('content4',3)
                window.onscroll=null
            }

        }
    }
    nuberScrollShow(target,step){
       let inter =  setInterval(() => {
            if((this.state[target].nub + step)<this.state[target].count){
                this.setState({
                    [target]:{
                        ...this.state[target],
                        nub:this.state[target].nub+step
                    }
                })
            }else{
                this.setState({
                    [target]:{
                        ...this.state[target],
                        nub:this.state[target].count
                    }
                })
                clearInterval(inter)
            }
        }, 50);
    }
    render() {
        return (
            <div className="why" >
                <div className="why-circle-small" />
                <div className="why-circle-large" />
                <div className="why-circle-middle" />
                <p className="why-title">为什么选择中孚</p>
                <div className="why-content" ref={this.box}>
                    <div style={{ flex: 6 }} />
                        <div className="why-tag">
                            <span className="why-tag-count">{this.state.content1.nub}</span>
                            <span className="why-tag-amount">{this.state.content1.amount}</span>
                            <p style={{ marginTop: "1em" }} className="why-tag-amount">{this.state.content1.name}</p>
                        </div>
                        <div className="why-tag">
                            <span className="why-tag-count">{this.state.content2.nub}</span>
                            <span className="why-tag-amount">{this.state.content2.amount}</span>
                            <p style={{ marginTop: "1em" }} className="why-tag-amount">{this.state.content2.name}</p>
                        </div>
                        <div className="why-tag">
                            <span className="why-tag-count">{this.state.content3.nub}</span>
                            <span className="why-tag-amount">{this.state.content3.amount}</span>
                            <p style={{ marginTop: "1em" }} className="why-tag-amount">{this.state.content3.name}</p>
                        </div>
                        <div className="why-tag">
                            <span className="why-tag-count">{this.state.content4.nub}</span>
                            <span className="why-tag-amount">{this.state.content4.amount}</span>
                            <p style={{ marginTop: "1em" }} className="why-tag-amount">{this.state.content4.name}</p>
                        </div>
                    <div style={{ flex: 6 }} />
                </div>
                <div className="why-partner">
                    <p className="why-partner-title">合作伙伴</p>
                    <div className="why-partner-content">
                        <img src={partner1} />
                        <div className="why-space" />
                        <img src={partner2} />
                        <div className="why-space" />
                        <img src={partner3} />
                        <div className="why-space" />
                        <img src={partner4} />
                        <div className="why-space" />
                        <img src={partner5} />
                        <div className="why-space" />
                        <img src={partner6} />
                        <div className="why-space" />
                        <img src={partner7} />
                        <div className="why-space" />
                        <img src={partner8} />
                    </div>
                </div>
            </div>)
    }
}
export default Why;
