import React , {Component} from 'react';
import './index.css';
class Ecology extends Component{
    constructor(props)
    {
        super(props);
        const width = document.body.clientWidth;
        const margin = width * 60 / 1920 ;
        this.state = {
            margin: margin
        };
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.resize);
    }
    resize = () =>{
        const width = document.body.clientWidth;
        const margin = width * 60 / 1920 ;
        this.setState({
            margin: margin
        })
    }
    render() {
        return(
            <div className="ecology">
                <p className="ecology-title">共赢生态</p>
                <div style={{backgroundImage: `url(${require('../../assets/img/ecology-bg.png')})`,backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',display: 'flex' ,marginTop: '2em'}}>
                    <div style={{flex: 1}}/>
                    <div className="ecology-circle" style={{marginTop: this.state.margin + 'px'}}>
                        <div className="ecology-circle-content">
                            <div style={{position: "relative",top:"50%",transform:"translateY(-50%)"}}>
                                <p style={{fontSize: '1.6em',margin: 0}}>能源</p>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 1}}/>
                    <div className="ecology-circle">
                        <div className="ecology-circle-content">
                            <div style={{position: "relative",top:"50%",transform:"translateY(-50%)"}}>
                                <p style={{fontSize: '1.2em',margin : 0}} >汽车</p>
                                <p style={{fontSize: '1.2em',margin : 0}}>后市场</p>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 1}}/>
                    <div className="ecology-circle" style={{marginTop: -this.state.margin + 'px'}}>
                        <div className="ecology-circle-content">
                            <div style={{position: "relative",top:"50%",transform:"translateY(-50%)"}}>
                                <p style={{fontSize: '1.2em',margin : 0}} >物流</p>
                                <p style={{fontSize: '1.2em',margin : 0}}>运输</p>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 1}}/>
                </div>
                <p className="ecology-desc">协同合作伙伴，联合打造共赢生态圈</p>
            </div>)
    }
}
export default Ecology;
