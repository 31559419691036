import React , {Component} from 'react';
import './index.css';
import core1 from '../../assets/img/gas-core1.jpg'
import core2 from '../../assets/img/gas-core2.jpg'
import core3 from '../../assets/img/gas-core3.jpg'
import core4 from '../../assets/img/gas-core4.jpg'
import core5 from '../../assets/img/gas-core5.jpg'
import core6 from '../../assets/img/gas-core6.jpg'

const core = [
    [
        {
            icon: core1,
            label: '一键支付',
            desc: '站点油品、非油品一键支付，方便快捷'
        },
        {
            icon: core2,
            label: '智能穿戴',
            desc: '自主研发的防爆智能穿戴终端，便于员工管理、订单校验、智能识别、动态营销等'
        },
        {
            icon: core3,
            label: '大数据报表',
            desc: '基于大数据分析，多维度实时统计站点销量、用户画像、利润分析等，为油站营销提供强有力的数据支撑'
        },
    ],
    [
        {
            icon: core4,
            label: '大客户管理',
            desc: '基于集团客户、大客户的整体解决方案，方便大客户营销、管理。'
        },
        {
            icon: core5,
            label: '油非互动',
            desc: '油站非油品油品销售一体化，同时提供线上商城，油品非油品相互串联、导流回流、实现站点销量、利润的双增长'
        },
        {
            icon: core6,
            label: '智能营销',
            desc: '多营销方式支撑，多营销渠道兼容，动态效果监控'
        }
    ]
]
class home extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
            hoverIndex: -1
        }
    }
    onEnter = (index) =>{
        this.setState({
            hoverIndex: index
        })
    }

    onLeave = (index) =>{
        this.setState({
            hoverIndex: -1
        })
    }
    render() {
        return(
            <div className="gas-core">
                {
                    core.map((row,rowIndex)=><div className="gas-core-row">
                        {
                            row.map((item,index)=><div
                                onMouseEnter={this.onEnter.bind(null,rowIndex * 3 + index)} onMouseLeave={this.onLeave.bind(null,rowIndex * 3 + index)}
                                style={{backgroundImage: "url(" + item.icon + ")",
                                width: 400,height:258,backgroundRepeat: 'no-repeat',display:'flex',
                                textAlign:'left',
                                alignItems:"start",justifyContent: 'flex-end',flexDirection:"column"}}>
                                <div style={{width:"100%",
                                    background: rowIndex * 3 + index == this.state.hoverIndex?'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1))':""}}>
                                <p className="gas-core-title">{item.label}</p>
                                {rowIndex * 3 + index == this.state.hoverIndex?
                                    <p className="gas-core-desc">{item.desc}</p>:""
                                }
                                </div>
                            </div>)
                        }
                    </div>)
                }

            </div>)
    }
}
export default home;
