import React , {Component} from 'react';
import Router from './Router'
import './App.css'
class App extends Component{
  componentWillMount(){
    if(window.screen.width<1200){
      window.location.href=window.location.origin+"/mobile/"
    }
  }
  render() {
    if(window.screen.width<1200){
      window.location.href=window.location.origin+"/mobile/"
    }else{
      return(<Router/>)
    }
  }
}

export default App;

